<template>
  <div class="signup-new-parents">
    <div class="container d-flex flex-lg-row flex-column">
      <div class="col-lg-8 signup-new-right">
        <div class="signup-new-parents-title">
          <div class="text">
            Инструкция по регистрации
          </div>
          <a class="back-btn" href="/new-main">{{ $t('signup.back-btn') }}</a>
        </div>
        <div class="signup-wrapper-info">
          <div class="signup-wrapper-info-left">
            <img style="margin-top: auto;margin-bottom: auto" width="74px" height="74px"
                 src="/images/exclamation-point.png" alt="">
            <div v-html="$t('signup.parent-text')" class="signup-wrapper-info-left-text"></div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-md-12">
            <div class="signup-requered-top-mob pt-0 pb-4 text-center">
              {{ $t('signup.requered-fields') }}
            </div>
            <form-group :field="$v.parent.iin" :label="$t('references.required-field') + $t('signup.iin-label')"
                        name="iin">
              <el-input
                  maxlength="12"
                  :placeholder="$t('signup.iin-placeholder')"
                  v-model="parent.iin"
                  @input="getDataFromIin"
                  @change="$v.parent.iin.$touch()"
                  show-word-limit>
              </el-input>
            </form-group>
            <form-group :field="$v.parent.last_name"
                        :label="$t('references.required-field') + $t('signup.last-name-label')" name="last_name">
              <el-input
                  :placeholder="$t('signup.last-name-placeholder')"
                  v-model="parent.last_name"
                  @change="$v.parent.last_name.$touch()"
                  clearable>
              </el-input>
            </form-group>
            <form-group :field="$v.parent.first_name"
                        :label="$t('references.required-field') + $t('signup.first-name-label')" name="first_name">
              <el-input
                  :placeholder="$t('signup.first-name-placeholder')"
                  v-model="parent.first_name"
                  @change="$v.parent.first_name.$touch()"
                  clearable>
              </el-input>
            </form-group>
            <form-group :field="$v.parent.patronymic" :label="$t('signup.patronymic-label')" name="patronymic">
              <el-input
                  :placeholder="$t('signup.patronymic-placeholder')"
                  v-model="parent.patronymic"
                  clearable>
              </el-input>
            </form-group>
            <form-group :field="$v.parent.birth_date"
                        :label="$t('references.required-field') + $t('signup.birth-date-label')" name="birth_date">
              <el-date-picker
                  style="width: 100%;"
                  v-model="parent.birth_date"
                  type="date"
                  placeholder="Дата"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  disabled
                  @change="$v.parent.birth_date.$touch()">
              </el-date-picker>
            </form-group>

          </div>
          <div class="col-lg-6 col-md-12">
            <form-group :field="$v.parent.gender" :label="$t('references.required-field') + $t('references.gender')"
                        name="gender" class="mob-hidden">
              <el-select
                  :placeholder="$t('references.gender')"
                  v-model="parent.gendernum"
              >
                <el-option
                    v-for="item in genders"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </form-group>
            <form-group :field="$v.account.email" :label="$t('references.email-label')" name="email">
              <el-input
                  @input="emailValidate"
                  id="email"
                  :placeholder="$t('references.email-placeholder')"
                  v-model="parent.email"
                  @change="$v.account.email.$touch()"
                  clearable>
              </el-input>
            </form-group>
            <form-group :field="$v.parent.password" :label="$t('signin-form.password-label')" name="password">
              <el-input
                  onkeyup="value=value.replace(/[^\d]/g,'')"
                  type="number"
                  maxlength="4"
                  :placeholder="$t('signin-form.password-placeholder')"
                  v-model="parent.password"
                  @input="onPasswordInput"
                  @change="$v.parent.password.$touch()"
                  show-password>
              </el-input>
            </form-group>
            <form-group :field="$v.parent.passwordConfirm"
                        :label="$t('references.required-field') + $t('signin-form.password-confirm-label')"
                        name="password">
              <el-input
                  onkeyup="value=value.replace(/[^\d]/g,'')"
                  type="number"
                  maxlength="4"
                  :placeholder="$t('signin-form.password-confirm-placeholder')"
                  v-model="parent.passwordConfirm"
                  @input="onPasswordConfirmInput"
                  @change="$v.parent.passwordConfirm.$touch()"
                  show-password></el-input>
            </form-group>
            <form-group :field="$v.parent.phone" :label="$t('references.required-field') + $t('references.phone-label')"
                        name="phone">
              <el-input
                  :disabled="parentPhoneVerified"
                  :placeholder="$t('references.phone-placeholder')"
                  v-model="parentPhone"
                  v-mask="'+7 (###) ### ##-##'"
                  @input="enforceParentPhoneFormat"
                  clearable>
              </el-input>
            </form-group>
          </div>
        </div>
        <div class="row" style="margin-bottom: 100px">
          <div class="col-lg-6 col-md-12">
            <div v-if="phoneVerified" class="phone-is-confirmed pb-4 pt-1">
              <img width="15" src="/images/profile/tick-green.svg" class="mr-2">
              {{ $t('signin-form.phone-is-confirmed') }}
            </div>
            <div class="confirm-phone-btn-block">
              <button class="confirm-phone-btn w-100" @click="openModal" type="button">
                {{ $t('signin-form.confirm-phone') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 signup-new-left">
        <div class="signin-block-back">
        </div>
        <div class="overlay"></div>
        <div class="password-recovery-block">
          <div class="signup-new-left-logo"></div>
          <div class="col-lg-9 col-12 px-0 instructions">
            <p class="text">Инструкция по регистрации:</p>
            <div style="justify-content: space-between" class="d-flex mob-column-signup">
              <a class="download-btn" :href="$t('instruction.auth_file')"
                 :download="$t('instruction.auth')">{{ $t('signin-form.download') }}</a>
            </div>
          </div>
          <div class="col-lg-9 col-12 px-0">
            <iframe class="instruction-video" src="https://www.youtube.com/embed/UzU6s4U8CXg"
                    frameborder="1"></iframe>
          </div>
        </div>
      </div>
      <b-modal id="youtube-modal" hide-footer>
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div>
                  <div class="embed-responsive embed-responsive-16by9">
                    <iframe width="560" height="315" :src="$t('signin-form.url-youtube')" title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>

      <b-modal id="error-modal" hide-footer hide-header>
        <div id="error-wrapper">
          <div id="dialog">
            <button @click="$bvModal.hide('error-modal')" type="button" class="close" data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true"></span>
            </button>
            <h3>{{ $t('signup.error-modal') }}</h3>
            <span>{{ errorMessage }}</span>
          </div>
        </div>
      </b-modal>

      <b-modal id="sms-modal" hide-footer hide-header centered>
        <button class="btn-close-top" @click="$bvModal.hide('sms-modal')">
          <img width="12" src="/images/profile/close.svg" alt="">
        </button>
        <h3 class="sms-modal-title">{{ $t('signup.enter-sms-code') }}</h3>
        <!-- <span>{{ $t('signup.sended-to') }} {{phone}}</span> -->
        <span v-if="parent.phone" class="sms-modal-text">{{ $t('signup.sended-to') }} {{ parentPhone }}</span>
        <span v-else class="sms-modal-text">{{ $t('signup.sended-to') }} {{ phone }}</span>
        <div id="form" class="sms-modal-form">
          <CodeInput :field-width="44" :field-height="56" :loading="sendingSms" :fields="4" class="input"
                     v-on:change="onChange" v-on:complete="onComplete"/>
          <p class="text-danger" v-if="invalidCode">{{ $t('signup.incorrect-code') }}</p>
        </div>
        <p style="cursor: pointer" v-if="canResend">
          <a class="resend-sms cursor-pointer" @click="resendSms">{{ $t('signup.resend-code') }}</a></p>
        <div v-if="!canResend" class="can-resend-sms">{{ $t('signup.resend-code-after') }}{{ resendSec }}
          {{ $t('signup.resend-code-seconds') }}
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import VueMask from 'v-mask'
import {mapActions} from 'vuex'
import {minLength, minValue, required, email, helpers} from "vuelidate/lib/validators";
import CodeInput from "vue-verification-code-input";

const passwordHelper = helpers.regex('passwordHelper', /\d{3}[0-9]/);


export default {
  name: 'Signup',
  beforeCreate() {
    Vue.use(VueMask);
  },
  components: {
    CodeInput,
  },
  data() {
    return {
      smsSended: false,
      genderDisable: true,
      isHiddenStudent: false,
      isHiddenParent: true,
      iinCorrect: true,
      emailCorrect: true,
      errorMessage: '',
      education: {},
      localities: [],
      regions: [],
      cities: [],
      phone: '',
      smsSendEror: false,
      parentPhone: '',
      sendingSms: false,
      regionsLoading: false,
      localitiesLoading: false,
      citiesLoading: false,
      invalidCode: false,
      canResend: true,
      phoneVerified: false,
      parentPhoneVerified: false,
      resendSec: 300,
      acceptData: false,
      rules: [
        {active: false, message: this.$t('reset-password.number-required'), regex: /\d{3}[1-9]/},
      ],
      genderLabel: '',
      submitted: false,
      passwordValid: false,
      password: '',
      confirm_password: '',
      genders: [
        {
          id: "1",
          name: this.$t('references.gender-male')
        },
        {
          id: "2",
          name: this.$t('references.gender-female')
        }
      ],
      role: '1',
      account: {
        iin: '',
        last_name: '',
        first_name: '',
        patronymic: '',
        birth_date: '',
        locality_id: '',
        region_id: '',
        city_id: '',
        phone: '',
        email: '',
        sex: '',
        gender: '',
        password: '',
        confirm_password: '',
      },
      parent: {
        gendernum: '',
        iin: '',
        last_name: '',
        first_name: '',
        patronymic: '',
        birth_date: '',
        phone: '',
        email: '',
        password: '',
        passwordConfirm: '',
      }
    }
  },
  validations: {
    phone: {
      required: false,
      minLength: minLength(18)
    },

    parentPhone: {
      required: false,
      minLength: minLength(18)
    },
    account: {
      iin: {required, minLength: minLength(12)},
      phone: {required, minLength: minLength(11)},
      email: {email, required: false},
      last_name: {required},
      first_name: {required},
      sex: {required},
      // patronymic: { required },
      birth_date: {required},
      region_id: {required},
      city_id: {required},
      locality_id: {required},

      password: {
        required, passwordHelper, minLength: minLength(4),
        valid: function (value) {
          return /\d{3}[0-9]/.test(value)
        }
      },

      confirm_password: {
        required, passwordHelper, minLength: minLength(4),
        valid: function (value) {
          return /\d{3}[0-9]/.test(value)

        }
      },
      //   sex: { required },
      //   gender: { required },
      // password: { required }
    },
    parent: {
      iin: {required, minLength: minLength(12)},
      phone: {required, minLength: minLength(11)},
      email: {email, required: false},
      last_name: {required},
      first_name: {required},
      birth_date: {required},
      password: {
        required, passwordHelper, minLength: minLength(4),
        valid: function (value) {
          return /\d{3}[0-9]/.test(value)
        }
      },
      passwordConfirm: {required, passwordHelper},
    }
  },
  computed: {
    notSamePasswords() {
      if (this.passwordsFilled) {
        return (this.password !== this.confirm_password)
      } else {
        return false
      }
    },
    passwordsFilled() {
      return (this.password !== '' && this.confirm_password !== '')
    },
    passwordValidation() {
      let errors = []
      for (let condition of this.rules) {
        condition.active = condition.regex.test(this.password);
        // if (!condition.regex.test(this.password)) {
        errors.push({
          message: condition.message,
          active: condition.active
        });
        // }
      }
      let error = false;
      Object.entries(errors).forEach(entry => {
        const [key, value] = entry;
        if (value.active == false) {
          error = true;
        }
      });
      this.passwordValid = !error;
      return {valid: !error, errors}
    }
  },
  mounted() {
    this.isHiddenStudent = true;
    this.isHiddenParent = false;
    this.role = '2'

    this.regionsLoading = true;
    this.$http.get(window.API_ROOT + '/api/references/regions')
        .then((res) => {
          if (res.body.data) {
            this.regionsLoading = false;
            this.regions = res.body.data;
          }
        }).catch((e) => {
      this.regionsLoading = false
    })
    if (sessionStorage.getItem('phone-verified')) {
      this.phoneVerified = (sessionStorage.getItem('phone-verified') == 'true');
    }
    if (sessionStorage.getItem('parent-phone-verified')) {
      this.parentPhoneVerified = (sessionStorage.getItem('parent-phone-verified') == 'true');
    }
    if (sessionStorage.getItem('account')) {
      this.account = JSON.parse(sessionStorage.getItem('account'));
      this.phone = this.account.phone;
      this.enforcePhoneFormat();
    }
    if (sessionStorage.getItem('parent')) {
      this.parent = JSON.parse(sessionStorage.getItem('parent'));
      this.enforceParentPhoneFormat();
    }
    if (this.account.email == null) {
      this.account.email = '';
    }
    if (this.parent.email == null) {
      this.parent.email = '';
    }
    if (this.account.region_id !== '') {
      this.$http.get(window.API_ROOT + '/api/references/districts/' + this.account.region_id)
          .then((res) => {
            if (res.body.data) {
              this.cities = res.body.data;
            }
          }).catch((e) => {
      })
    }

    if (this.account.city_id !== '') {
      this.$http.get(window.API_ROOT + '/api/references/localities/' + this.account.city_id)
          .then((res) => {
            if (res.body.data) {
              this.localities = res.body.data;
            }
          }).catch((e) => {
      })
    }
  },
  methods: {
    sendRegparent() {
      if (this.parent.gendernum == this.$t('references.gender-male')) {
        this.parent.gendernum = 1;
      } else if (this.parent.gendernum == this.$t('references.gender-female')) {
        this.parent.gendernum = 2;
      }
      this.$http.post(window.API_ROOT + '/api/user', {
        iin: this.parent.iin,
        first_name: this.parent.first_name,
        last_name: this.parent.last_name,
        patronymic: this.parent.patronymic,
        email: this.parent.email,
        sex: this.parent.gendernum,
        phone: this.parent.phone,
        birth_date: this.parent.birth_date,
        password: this.parent.password,
        confirm_password: this.parent.passwordConfirm,
        interface_lang: this.$i18n.locale,
        role: 2,
      })
          .then((res) => {
            if (res.status == 201) {
              sessionStorage.removeItem('parent');
              sessionStorage.removeItem('parent-phone-verified');

              Vue.toastr({
                message: 'Success',
                description: 'Пользователь успешно создан',
                type: 'success'
              })
            }

            this.$router.push({path: '/signin'})
          }).catch((e) => {
        this.loading = false;

        if (e.status == 400 && e.body.errors) {
          e.body.errors.forEach(function (val) {
            Vue.toastr({
              message: 'Error',
              description: val,
              type: 'error'
            })
          })
        }
      })
    },
    emailValidate(val) {
      if (val != '') {
        this.emailCorrect = false;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var emailBool = (re.test(String(val).toLowerCase()))

        if (emailBool == true) {
          this.$http.get(window.API_ROOT + '/api/check-email?email=' + val).then((res) => {
            console.log(res.body.message)
            if (res.body != false) {
              if (res.body.message != 'ok') {
                this.emailCorrect = false;
                this.errorMessage = res.body.message;
                this.$bvModal.show('error-modal');
              } else if (res.body.message == 'ok') {
                this.emailCorrect = true;
              }
            }
          })
        } else {
          this.errorMessage = this.$t('form-group.email-required');

        }
      }
      if (val == '') {

        this.emailCorrect = true;
      }
    },
    getDataFromIin(val) {
      if (val.length == 12) {
        this.$http.post(window.API_ROOT + '/api/check-iin', {
          iin: val,
          role: this.role
        })
            .then((res) => {
              if (res.body.status == 'error') {
                this.iinCorrect = false;
                this.errorMessage = res.body.error[0];
                this.$bvModal.show('error-modal');
              }
              if (res.body.status == 'success') {

                let date = new Date();

                this.iinCorrect = true;
                let year;
                // let monthVal;
                if (parseInt('20' + val.slice(0, 2), 10) > date.getFullYear()) {
                  year = parseInt('19' + val.slice(0, 2), 10);
                } else {
                  year = parseInt('20' + val.slice(0, 2), 10);
                }

                let month = val.slice(2, 4)
                let day = val.slice(4, 6)

                if (this.role == '1') {
                  this.iinCorrect = true;
                  this.account.birth_date = `${year.toString()}-${month}-${day}`;


                  if (this.account.iin[6] == 3 || this.account.iin[6] == 5 || this.account.iin[6] == 7 || this.account.iin[6] == 9) {
                    this.account.sex = this.$t('references.gender-male');

                    this.genderLabel = this.$t('references.gender-male');
                  } else if (this.account.iin[6] == 4 || this.account.iin[6] == 6 || this.account.iin[6] == 8) {
                    // this.account.sex = this.$t('references.gender-female');
                    this.genderLabel = this.$t('references.gender-female');
                    this.account.sex = this.$t('references.gender-female');
                  } else if (this.account.iin[6] == 0) {
                    this.genderDisable = false
                  } else {
                    this.account.sex = '';
                  }
                } else {
                  this.iinCorrect = true;
                  this.parent.birth_date = `${year.toString()}-${month}-${day}`;
                  if (this.parent.iin[6] == 3 || this.parent.iin[6] == 5) {
                    this.parent.gendernum = this.$t('references.gender-male');
                  } else if (this.parent.iin[6] == 4 || this.parent.iin[6] == 6) {
                    this.parent.gendernum = this.$t('references.gender-female');
                  } else {
                    this.parent.gender = '';
                    this.parent.gendernum = '';
                  }
                }

              }

            })
            .catch((e) => {
              Vue.toastr({
                message: 'Error',
                description: e.message,
                type: 'error'
              })
            })
      }
    },
    getCities(id) {
      this.$v.account.region_id.$touch();
      this.citiesLoading = true;
      this.$http.get(window.API_ROOT + '/api/references/districts/' + id)
          .then((res) => {
            this.citiesLoading = false;
            if (res.body.data) {
              this.cities = res.body.data;
              this.account.city_id = '';
            }
          }).catch((e) => {
        this.citiesLoading = false;
      })
    },
    getLocalities(id) {
      this.$v.account.city_id.$touch();
      this.localitiesLoading = true;
      this.$http.get(window.API_ROOT + '/api/references/localities/' + id)
          .then((res) => {
            this.localitiesLoading = false;
            if (res.body.data) {
              this.localities = res.body.data;
              this.account.locality_id = '';
            }
          }).catch((e) => {
        this.localitiesLoading = false;
      })
    },
    openModal() {
      // if (this.iinCorrect==false || this.emailCorrect==false) {
      //   this.$bvModal.show('error-modal');
      //   return;
      // }

      if (this.iinCorrect == false) {
        this.$bvModal.show('error-modal');
        return;
      }

      switch (this.role) {
        case '1':
          this.$v.account.$touch();
          if (this.$v.account.$invalid) return
          if (!this.phoneVerified) {

            if (this.resendSec == 300) {
              setInterval(() => {
                this.resendSec--;
                if (this.resendSec == 0) {
                  this.canResend = true;
                }
              }, 1000);
            }
            if (this.canResend == true) {
              this.canResend = false
              this.sendSms({
                phone: this.account.phone,
                locale: this.$i18n.locale
              }).then((res) => {
                this.resendSec == 300
                this.smsSended = true
                if (this.smsSendEror == false) {
                  this.$bvModal.show('sms-modal');
                }
                this.smsSendEror = false
              }).catch(() => {
                this.smsSendEror = true
                this.smsSended = false
                this.$bvModal.hide('sms-modal');
                Vue.toastr({
                  message: this.$t('profile.settings.error'),
                  description: this.$t('signin-form.sms-error'),
                  type: 'error'
                });
              })
            } else {
              if (this.smsSendEror == true) {
                this.sendSms({
                  phone: this.account.phone,
                  locale: this.$i18n.locale
                }).then((res) => {
                  this.resendSec == 300
                  this.smsSended = true
                  if (this.smsSendEror == false) {
                    this.$bvModal.show('sms-modal');
                  }
                  this.smsSendEror = false
                }).catch(() => {
                  this.smsSendEror = true
                  this.smsSended = false
                  this.$bvModal.hide('sms-modal');
                  Vue.toastr({
                    message: this.$t('profile.settings.error'),
                    description: this.$t('signin-form.sms-error'),
                    type: 'error'
                  });
                })
              }
              if (this.smsSendEror == false) {
                this.$bvModal.show('sms-modal');
              }
            }


          }
          break;
        case '2':
          this.$v.parent.$touch();
          if (this.$v.parent.$invalid) return
          if (!this.parentPhoneVerified) {

            if (this.resendSec == 300) {
              setInterval(() => {
                this.resendSec--;
                if (this.resendSec == 0) {
                  this.canResend = true;
                }
              }, 1000);
            }
            if (this.canResend == true) {
              this.canResend = false
              this.sendSms({
                phone: this.parent.phone,
                locale: this.$i18n.locale
              }).then((res) => {
                this.resendSec == 300
                this.smsSended = true
                if (this.smsSendEror == false) {
                  this.$bvModal.show('sms-modal');
                }
                this.smsSendEror = false
              }).catch(() => {
                this.smsSendEror = true
                this.smsSended = false
                this.$bvModal.hide('sms-modal');
                Vue.toastr({
                  message: this.$t('profile.settings.error'),
                  description: this.$t('signin-form.sms-error'),
                  type: 'error'
                });
              })
            } else {
              if (this.smsSendEror == true) {
                this.sendSms({
                  phone: this.parent.phone,
                  locale: this.$i18n.locale
                }).then((res) => {
                  this.resendSec == 300
                  this.smsSended = true
                  if (this.smsSendEror == false) {
                    this.$bvModal.show('sms-modal');
                  }
                  this.smsSendEror = false
                }).catch(() => {
                  this.smsSendEror = true
                  this.smsSended = false
                  this.$bvModal.hide('sms-modal');
                  Vue.toastr({
                    message: this.$t('profile.settings.error'),
                    description: this.$t('signin-form.sms-error'),
                    type: 'error'
                  });
                })
              }
              if (this.smsSendEror == false) {
                this.$bvModal.show('sms-modal');
              }
            }


          }
          break;
      }
    },
    resendSms() {
      if (this.role == '1') {
        this.canResend = false
        this.sendSms({
          phone: this.account.phone,
          locale: this.$i18n.locale
        }).then((res) => {
          this.$bvModal.show('sms-modal');
        }).catch(() => {
          this.$bvModal.hide('sms-modal');
          this.smsSendEror = true
          this.canResend = true
          Vue.toastr({
            message: this.$t('profile.settings.error'),
            description: this.$t('signin-form.sms-error'),
            type: 'error'
          });
        })
        this.resendSec = 300
        if (this.resendSec == 300) {
          setInterval(() => {
            this.resendSec--;
            if (this.resendSec == 0) {
              this.canResend = true;
            }
          }, 1000);
        }
      }
      if (this.role == '2') {
        this.canResend = false
        this.sendSms({
          phone: this.parent.phone,
          locale: this.$i18n.locale
        }).then((res) => {
          this.$bvModal.show('sms-modal');
        }).catch(() => {
          this.$bvModal.hide('sms-modal');
          this.smsSendEror = true
          this.canResend = true
          Vue.toastr({
            message: this.$t('profile.settings.error'),
            description: this.$t('signin-form.sms-error'),
            type: 'error'
          });
        })
        this.resendSec = 300
        if (this.resendSec == 300) {
          setInterval(() => {
            this.resendSec--;
            if (this.resendSec == 0) {
              this.canResend = true;
            }
          }, 1000);
        }
      }
    },
    enforcePhoneFormat() {
      this.$v.phone.$touch();
      let x = this.phone
          .replace(/\D/g, "")
          .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);

      this.account.phone = x[0];
    },
    enforceParentPhoneFormat() {
      this.$v.phone.$touch();
      let x = this.parentPhone
          .replace(/\D/g, "")
          .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);

      this.parent.phone = x[0];
    },
    onChange(v) {
      // console.log('on change');
    },
    onComplete(v) {
      this.invalidCode = false;
      this.sendingSms = true;
      this.$http.post(window.API_ROOT + '/api/sms-verification', {
        phone: (this.role == '1') ? this.account.phone : this.parent.phone,
        code: v,
      }).then((res) => {
        if (res.body.status == 'success') {
          if (this.role == '1') {
            sessionStorage.setItem('account', JSON.stringify(this.account));
            sessionStorage.setItem('phone-verified', true);
            this.phoneVerified = true;
          } else {
            sessionStorage.setItem('parent', JSON.stringify(this.parent));
            sessionStorage.setItem('parent-phone-verified', true);
            this.parentPhoneVerified = true;
            // parent create
            this.sendRegparent();
          }
          this.$bvModal.hide('sms-modal');
        }
        if (res.body.status == 'error') {
          this.invalidCode = true;
        }
        this.sendingSms = false;
      }).catch(() => {
        this.sendingSms = false;
      })
    },
    status(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty
      }
    },
    onSubmit(data) {
      this.login(data)
          .then(() => {
            this.$router.push(this.$route.query.redirect || '/')

          })
          .catch(() => {
          })
    },
    onPasswordInput(e) {
      this.account.password = this.password;
    },
    onPasswordConfirmInput(e) {
      this.account.confirm_password = this.confirm_password;
    },
    savePassword() {
      this.$v.$touch()
      if (this.$v.$invalid || !this.passwordValid) return
      if (this.password === this.confirm_password) {
        this.$store.commit('SAVE_PASSWORD', this.password);
        this.$router.push({path: '/signup/education'})
      } else {
        Vue.toastr({
          message: 'Error',
          description: this.$t('signup.passwords-dont-match'),
          type: 'error'
        })
      }
    },
    createUser() {
      this.loading = true;
      let form = {};
      let phoneVerified = false;
      if (this.role == '1') {
        form = this.account;
        phoneVerified = this.phoneVerified;
      } else {
        form = this.parent;
        phoneVerified = this.parentPhoneVerified;
      }
      form.role = this.role;
      if (phoneVerified) {

        if (this.account.sex == this.$t('references.gender-male')) {
          this.account.sex = 1;
        } else if (this.account.sex == this.$t('references.gender-female')) {
          this.account.sex = 2;
        }


        this.$http.post(window.API_ROOT + '/api/user', form)
            .then((res) => {
              this.loading = false;
              if (res.status == 201) {
                sessionStorage.removeItem('account');
                sessionStorage.removeItem('parent');
                sessionStorage.removeItem('education');
                sessionStorage.removeItem('avatar');
                sessionStorage.removeItem('avatar-base64');
                sessionStorage.removeItem('phone-verified');
                this.$store.commit('FORGET_PASSWORD', this.password);

                // this.$bvModal.show('finish-modal');

                Vue.toastr({
                  message: 'Success',
                  description: this.$t('profile.settings.user-create-success'),
                  type: 'success'
                })

                localStorage.setItem('token', res.body.token)
                localStorage.setItem('role', res.body.role)

                if (this.role == 1) {
                  this.$router.push(this.$route.query.redirect || '/profile')
                }
                if (this.role == 2) {
                  this.$router.push(this.$route.query.redirect || '/profile-parent')
                }
              }
            })
            .catch((e) => {
              this.loading = false;
              if (e.status == 400 && e.body.errors) {
                e.body.errors.forEach(function (val) {
                  Vue.toastr({
                    message: 'Error',
                    description: val,
                    type: 'error'
                  })
                })
              }
            })
      } else {
        this.errorMessage = this.$t('profile.settings.please-submit-phone');
        this.$bvModal.show('error-modal');
      }
    },
    ...mapActions({
      'sendSms': 'sendSms',
      'checkSms': 'checkSms'
    })
  }
}
</script>

<style scoped>

.signup-new-parents {

}

.signup-new-right {
  padding-right: 40px;
  margin-left: auto;
  padding-left: 0;
}

.signup-wrapper-info-left {
  width: 100%;
}

.signup-wrapper-info {
  margin: 20px 0;
}

.signup-new-parents-title {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.signup-new-parents-title .text {
  color: #0C2B58;
  font-size: 30px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}

.back-btn {
  color: #8692A6;
  font-size: 20px;
  font-weight: 500;
  position: relative;
  padding-left: 30px;
  text-decoration: none;
  margin-bottom: 30px;
  display: inline-block;
}

.back-btn::before {
  width: 24px;
  height: 24px;
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  background-image: url("../../../public/images/arrow_back_24px.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.signin-block-back {
  position: absolute;
  top: 0;
  left: 0;
  height: 200px;
  min-height: 100%;
  width: 300%;
  background-image: url("../../../public/images/signin-back.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 300%;
  background-color: #276CC2C7;
}

.password-recovery-block .instructions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1520px) {
  .password-recovery-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
  }

  .password-recovery-block .instructions {
    flex-direction: column;
  }

  .instructions .text {
    width: 100%;
  }

  .instructions .mob-column-signup {
    width: 100%;
    margin-bottom: 20px;
  }

  .instructions .download-btn {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 992px) {
  .signup-new-right {
    padding-right: 0px;
  }

  .signup-new-left {
    padding-top: 20px;
    padding-left: 0;
    padding-right: 0;
  }

  .signin-block-back {
    display: none;
  }

  .overlay {
    display: none;
  }

  .instructions .text {
    width: 100%;
    color: #343F68;
  }

  .password-recovery-block {
    width: 100%;
  }

  .signup-new-left .download-btn {
    background-color: #18A0FB;
  }

  .signup-new-left-logo {
    background-image: url("../../../public/images/Qabilet-Final-footer-light.svg");
  }
}

</style>

<style>
.signup-wrapper-info-right-text {
  max-width: 363px;
  margin-left: 50px;
  font-size: 14px;
  line-height: 150%;
}

.signup-wrapper-info-border {
  height: 100%;
  margin-left: 85px;
  border-right: 2px solid #18A0FB;;
}

.signup-new-right .signup-wrapper-info-left-text {
  margin-left: 50px;
  font-size: 13px;
  line-height: 150%;

  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

.signup-wrapper-info-right {
  width: 50%;
}

.signup-wrapper-info-left {
  width: 70%;
  display: flex;
}

.signup-wrapper-info {
  margin-top: 65px;
  display: flex;
  flex-wrap: wrap;
}

.toggle-wrapper {
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
}

.toggle-title {
  margin-top: 30px;
  color: #18A0FB;
}

.toggle-button {
  user-select: none;
  margin-top: 10px;
  cursor: pointer;
  margin-right: 20px;
  text-align: center;
  width: 200px;
  line-height: 46px;
  font-weight: 500;
  font-size: 13px;
  color: #18A0FB;
  background: #F7F8F9;
  border-radius: 10px;
}

.toggle-active-button {
  user-select: none;
  margin-top: 10px;
  cursor: pointer;
  text-align: center;
  width: 200px;
  line-height: 46px;
  font-weight: 500;
  font-size: 13px;
  color: white;
  background: #18A0FB;
  border-radius: 10px;
}

.margin-download {
  margin-left: 3rem;
}

.download-instruction {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  text-decoration: none;
  max-width: 184px;
  display: block;
  padding: 1px 16px;
  position: static;
  height: 32px;

  font-size: 13px;
  line-height: 32px;
  text-align: center;
  color: #0070D2;
  flex: none;
  order: 1;
  flex-grow: 0;
  background: #FFFFFF;
  border: 1px solid #DDDBDA;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 30px;
}

.download-instruction:hover {
  text-decoration: none;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.el-select {
  margin-bottom: 0;
}

.checkbox-block {
  display: flex;
  flex-direction: row;
}

.form-check-label {
  display: block;
  position: relative;
  padding-left: 26px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.form-check-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid #eee;
  border-radius: 50%;
}

.form-check-label:hover input ~ .checkmark {
  background-color: #eee;
}

.form-check-label input:checked ~ .checkmark {
  background-color: #18A0FB;
}

.form-check-label input:checked .form-check-label {
  background-color: #18A0FB;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.form-check-label input:checked ~ .checkmark:after {
  display: block;
}

.form-check-label .checkmark:after {
  top: 3px;
  left: 3px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.resend-sms {
  font-size: 16px;
  color: #333;
  display: block;
  margin-top: 20px;
}

.el-button.btn-finish.is-disabled {
  background: #CACACA;
  border: 1px solid #CACACA;
  color: #FFFFFF;
}

.btn-finish {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  background: #18A0FB;
  border-radius: 5px;
  padding: 13px 44px;
  color: #FFFFFF;
  margin: 0;
}

.btn-finish:hover, .btn-finish:focus {
  color: #FFFFFF;
  background: #18A0FB;
}

.signup-requered-top-mob {
  display: none;
}

.signup-accept-block {
  display: flex;
  flex-direction: column;
}

.signup-submit-block {
  display: flex;
  justify-content: left;
}

.gender-mob {
  display: none;
}

@media (max-width: 991px) {
  .signin-form {
    box-shadow: 0px 4px 4px rgb(51 51 51 / 4%), 0px 4px 16px rgb(51 51 51 / 8%);
    border-radius: 8px;
    padding: 25px 21px;
    background: #fff;
  }

  .signup-requered-top {
    display: none;
  }

  .signup-requered-top-mob {
    display: block;
  }

  .signup-accept-block {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 16px;
  }

  .signup-requered-fields {
    margin-top: 16px;
  }

  .signup-submit-block {
    justify-content: center;
  }

  .gender-mob {
    display: none;
  }

  .gender-mob .el-radio-group {
    text-align: center;
  }

  .gender-mob .el-radio-button__inner {
    padding: 10px 31px;

  }

  .gender-mob .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background: #18A0FB;
  }

  /* .mob-hidden {
    display: none;
  } */
}

@media (max-width: 768px) {
  .btn-block {
    text-align: center;
  }
}

@media (max-width: 430px) {
  .margin-download {
    margin-left: 0;
  }

  .download-instruction {
    max-width: 100%;
  }

  .mob-column-signup {
    flex-direction: column !important
  }
}

@media (max-width: 499px) {
  .toggle-button {
    width: 100%;

  }

  .toggle-active-button {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .signup-h3 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
    text-align: center;
  }

  .signup-wrapper-info {
    flex-direction: column;
  }

  .toggle-wrapper {
    justify-content: center;
  }

  .signup-wrapper-info-left-text {
    max-width: unset;
  }

  .signup-wrapper-info-left {
    width: 100%
  }

  .signup-wrapper-info-right {
    width: 100%;
  }

  .signup-wrapper-info-right-text {
    margin-left: 123px;
    margin-top: 2rem;
    max-width: unset;
  }

  .signup-wrapper-info-border {
    display: none;
  }
}
</style>